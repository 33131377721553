import Typography from "@mui/material/Typography";

export const getBookingConfirmationMessage = (numberOfGuests) => {
  if (numberOfGuests > 5) {
    return (
      <>
        <Typography variant="body" paragraph>
          Thanks for your request to book a table.
        </Typography>
        <Typography variant="body" paragraph>
          We'll be in touch to confirm your booking request as soon as possible.
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="body" paragraph>
          Your booking is confirmed.
        </Typography>
        <Typography variant="body" paragraph>
          We look forward to welcoming you at our restaurant.
        </Typography>
      </>
    );
  }
};
