import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Café Amaranth is a popular Thai restaurant located in the heart of
        Earlsfield. This restaurant offers an extensive menu of authentic Thai
        cuisine, from classic Pad Thai to spicy curries, all prepared using
        fresh and high-quality ingredients. The restaurant has a modern and
        elegant interior design that creates a warm and welcoming atmosphere for
        diners.
      </Typography>

      <Typography variant="body1" paragraph>
        One of the standout features of Café Amaranth is their takeaway service
        for collection. Customers can enjoy the restaurant's delicious dishes in
        the comfort of their own homes. The staff are friendly and
        accommodating, making the takeaway experience as smooth and enjoyable as
        possible. For those who love Thai food and want to enjoy it in the
        comfort of their own homes, Café Amaranth is a must-try takeaway option
        in Earlsfield.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
